/* 
 * All colors used on the website.
 */

$white:               #fff;
$black:               #000000;
$off-white:           #f8f8f8;

$blue:                #2A3B4B;
$blue-dark:           #000000;

$grey-darkest:        #20272E;
$grey-darker:         #1B1E24;
$grey-dark:           #4A4A4A;
$grey:                #9EADBD;
$grey-light:          #787878;
$grey-lighter:        #D8D8D8;
$grey-lightest:       #F8F8F8;

$succes:              #5cb85c;
$warning:             #f0ad4e;
$error:               #d9534f;

// Assign colors

$body-bg:             $white;
$body-color:          $grey-darker;
$selection-bg:        $black;
$selection-color:     $white;
$link-color:          $black;
$link-hover-color:    $grey-dark;

$primary-color:       #F3C27E;
$secondary-color:     #BBC5B4;

$button-bg:           $primary-color;
$button-hover-bg:     $secondary-color;
$button-color:        $white;
