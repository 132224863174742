/*
 * Grid, Breakpoints & Widths
 */

$site-width:          100%;
$site-min-width:      320px;
$site-max-width:      1400px;

$breakpoints: (
  xs:   380px,
  sm:   576px,
  md:   768px,
  lg:   992px,
  xl:   1280px,
  xxl:  1450px
);