/* 
 * Typography
 */

 @import url('https://fonts.googleapis.com/css?family=Heebo:300,400,500,700|Merriweather:400,700&display=swap');

$font-family-base:    'Heebo', sans-serif;
$font-family-heading: 'Heebo', sans-serif;
$font-family-special: 'Merriweather', serif;

$font-weight-light:   300;
$font-weight-regular: 400;
$font-weight-medium:  500; 
$font-weight-bold:    700;
$font-weight-black:   900;

$font-size-min:       14px;
$font-size-max:       16px;
$font-size-base:      1.25rem;
$font-size-lg:        1.25rem;
$font-size-sm:        0.875rem;
$font-size-xs:        0.75rem;

$line-height:         1.5;

h1,
h2,
h3,
h4{
    font-family: $font-family-heading; 
}