header, .hero {
   height: 100vh;
   position: relative;
   background-size: cover;
   background-position: center;

   .wrapper {
      position: relative;
      height: 100%;

      .content {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         z-index: 3;
         max-width: 800px;

         h1 {
            color: $white;
            font-size: 3.8rem;
            margin: 0;
            padding: 0;
            line-height: 3.8rem;
            margin-bottom: 15px;
         }

         p {
            color: $white;
         }
      }
   }
}

.socials {
   position: absolute;
   right: 20px;
   bottom: 20px;
   @extend .reset;
   z-index: 3;

   li {
      display: inline-block;
      margin-right: 20px;

      a {
         transition: all 350ms ease;

         &:hover {
            opacity: 0.7;
         }
      }
   }
}

.hero {
   height: 60vh;
   min-height: 550px;
}