@charset "UTF-8";
/*
 * Author: Wilco van Meppelen
 *
 * SCSS structure:
 * 0 - Vendor — Optional third party imports
 * 1 - Settings — preprocessors, fonts, variables
 * 2 - Tools — mixins, functions
 * 3 - Base — reset / normalize
 * 4 - Elements — HTML elements 
 * 5 - Components — UI components
 * 6 - Helpers - helper classes
 */
/* 
 * All colors used on the website.
 */
/* 
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Heebo:300,400,500,700|Merriweather:400,700&display=swap");
h1,
h2,
h3,
h4 {
  font-family: "Heebo", sans-serif; }

/*
 * Grid, Breakpoints & Widths
 */
/* 
 * Form field base
 */
input[type=date], input[type=text], input[type=email], input[type=url], input[type=search], input[type=password], input[type=tel], textarea {
  width: 100%;
  max-width: 400px;
  padding: .8em 1em !important;
  border: 1px solid #787878;
  background: transparent;
  color: #787878;
  outline: 0;
  border-radius: 100px;
  font-size: 1.1rem; }
  input:focus[type=date], input:focus[type=text], input:focus[type=email], input:focus[type=url], input:focus[type=search], input:focus[type=password], input:focus[type=tel], textarea:focus {
    border-color: #1B1E24;
    background: transparent; }

select {
  width: 100%;
  max-width: 400px;
  background: #fff url("../images/down-arrow.svg") no-repeat right 15px center;
  background-size: 15px 15px;
  appearance: none;
  border: 2px solid #000000;
  outline: 0;
  padding: .8em 1em;
  line-height: normal;
  border-radius: 5px;
  font-size: 1.125rem; }
  select:focus {
    background: #fff url(../images/up-arrow.svg);
    border-color: #000000; }

/* 
 * Button base
 */
/* 
 * Mixins
 */
/* 
 * Mixin for Media Queries
 */
/* 
 * Grid mixin
 */
/* 
 * Reset HTML elements and layout
 */
::selection {
  color: #fff;
  background: #000000; }

*,
input[type="search"] {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  font-size: 14px; }
  @media (min-width: 768px) {
    html {
      font-size: 15px; } }
  @media (min-width: 992px) {
    html {
      font-size: 16px; } }

body {
  background: #fff;
  color: #1B1E24;
  font-family: "Heebo", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0; }

a {
  color: #000000;
  transition: color 0.3s ease-in-out; }
  a:hover {
    color: #4A4A4A;
    text-decoration: none; }

li {
  font-size: 1.25rem;
  line-height: 1.8; }

p {
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 1.25rem;
  line-height: 1.5; }
  p:last-child {
    margin-bottom: 0; }

b,
strong {
  font-weight: 700; }

embed,
img,
object,
video {
  display: block;
  max-width: 100%;
  height: auto; }

/* 
 * Layout base
 */
body {
  width: 100%;
  background: #fff; }

a {
  color: #F3C27E;
  text-decoration: none; }

section {
  position: relative;
  padding: 80px 0; }

.wrapper {
  width: 100%;
  max-width: 1370px;
  margin: 0 auto;
  position: relative;
  display: block;
  padding: 0 20px; }
  @media (max-width: 1279px) {
    .wrapper {
      padding: 0 40px; } }
  @media (max-width: 767px) {
    .wrapper {
      padding: 0 20px; } }

.address-nav {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #fff;
  height: 50px;
  overflow: hidden; }
  .address-nav .get_address {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1rem;
    right: 0;
    padding: 20px 45px;
    box-sizing: border-box;
    border-left: 1px solid #fff; }

nav {
  position: fixed;
  top: 0;
  left: 0;
  top: 0;
  height: 80px;
  width: 100%;
  z-index: 99;
  padding: 0 20px;
  top: 50px;
  transition: all 450ms ease; }
  nav.scrolled {
    background: #BBC5B4;
    height: 60px;
    top: 0;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1); }
    nav.scrolled img {
      width: 60px;
      margin-top: 10px; }
    nav.scrolled ul {
      top: -5px !important; }
      nav.scrolled ul li.active a:not(.button) {
        color: #1B1E24 !important; }
  nav img {
    width: 140px;
    margin-top: 20px;
    transition: all 350ms ease; }
    @media (max-width: 767px) {
      nav img {
        width: 80px; } }
  nav .hamburger {
    display: none;
    width: 30px;
    height: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
    z-index: 100; }
    nav .hamburger span {
      display: block;
      background: #fff;
      border-radius: 35px;
      height: 2px;
      width: 100%;
      position: absolute;
      transition: all 450ms ease; }
      nav .hamburger span:first-child {
        top: 0; }
      nav .hamburger span:nth-child(2) {
        top: 50%;
        transform: translateY(-50%); }
      nav .hamburger span:last-child {
        top: 100%; }
    @media (max-width: 767px) {
      nav .hamburger {
        display: block; } }
    nav .hamburger.active span {
      background: #BBC5B4; }
      nav .hamburger.active span:first-child {
        top: 50%;
        transform: translateY(-50%);
        transform: rotate(-45deg); }
      nav .hamburger.active span:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
        width: 0px;
        opacity: 0; }
      nav .hamburger.active span:last-child {
        top: 50%;
        transform: translateY(-50%);
        transform: rotate(45deg); }
  nav ul {
    position: absolute;
    right: 20px;
    top: 0;
    transition: all 450ms ease;
    z-index: 3; }
    nav ul li {
      list-style: none;
      display: inline-block; }
      nav ul li a {
        padding: 20px 35px;
        display: block;
        color: #fff;
        font-size: 1rem; }
        nav ul li a:hover {
          color: #F3C27E; }
      nav ul li.active a {
        color: #F3C27E; }
    @media (max-width: 767px) {
      nav ul {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        background: rgba(255, 255, 255, 0.95);
        text-align: center;
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden; }
        nav ul li {
          display: block; }
          nav ul li a {
            color: #000000; }
          nav ul li.active a {
            color: #F3C27E; } }
  nav.open {
    top: 0; }
    nav.open ul {
      padding-top: 20%;
      opacity: 1;
      visibility: visible;
      transform: translateY(0%); }

.quote {
  padding: 80px 0; }
  .quote .wrapper {
    font-family: "Merriweather", serif;
    font-size: 3.8rem;
    line-height: 4rem;
    text-align: center;
    max-width: 800px;
    font-style: italic; }

footer {
  background-color: #F3C27E;
  padding: 40px 0;
  color: #fff;
  font-size: 1rem; }
  footer .wrapper {
    display: flex;
    flex-flow: row wrap;
    flex: 0 1 auto; }
    footer .wrapper > * {
      flex: none;
      margin-right: calc(20px);
      margin-bottom: calc(20px);
      width: calc((100% / 1) - (20px * (1 - 1) / 1)); }
      @media (max-width: 575px) {
        footer .wrapper > *:nth-child(1n) {
          margin-right: 0; } }
      @media (min-width: 576px) and (max-width: 767px) {
        footer .wrapper > * {
          width: calc((100% / 1) - (20px * (1 - 1) / 1)); }
          footer .wrapper > *:nth-child(1n) {
            margin-right: 0; } }
      @media (min-width: 768px) and (max-width: 991px) {
        footer .wrapper > * {
          width: calc((100% / 2) - (20px * (2 - 1) / 2)); }
          footer .wrapper > *:nth-child(2n) {
            margin-right: 0; } }
      @media (min-width: 992px) and (max-width: 1279px) {
        footer .wrapper > * {
          width: calc((100% / 3) - (20px * (3 - 1) / 3)); }
          footer .wrapper > *:nth-child(3n) {
            margin-right: 0; } }
      @media (min-width: 1280px) {
        footer .wrapper > * {
          width: calc((100% / 3) - (20px * (3 - 1) / 3)); }
          footer .wrapper > *:nth-child(3n) {
            margin-right: 0; } }
    @supports (display: grid) {
      footer .wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px; }
        @media (min-width: 576px) {
          footer .wrapper {
            grid-template-columns: repeat(1, 1fr); } }
        @media (min-width: 768px) {
          footer .wrapper {
            grid-template-columns: repeat(2, 1fr); } }
        @media (min-width: 992px) {
          footer .wrapper {
            grid-template-columns: repeat(3, 1fr); } }
        @media (min-width: 1280px) {
          footer .wrapper {
            grid-template-columns: repeat(3, 1fr); } }
        footer .wrapper > * {
          width: 100%;
          margin: 0; } }
    footer .wrapper .branding img {
      width: 120px;
      margin-bottom: 20px; }
    footer .wrapper .times p, footer .wrapper .address p {
      font-size: 1rem; }
    footer .wrapper .times .socials, footer .wrapper .address .socials {
      position: relative !important;
      margin-left: 20px;
      margin-top: 35px !important; }

/* 
 * Form fields
 */
input[type=date], input[type=text], input[type=email], input[type=url], input[type=search], input[type=password], input[type=tel] {
  padding: 20px;
  border-color: #D8D8D8; }

textarea {
  border-color: #D8D8D8;
  padding: 20px; }

/* 
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: #20272E;
  margin: 0;
  padding: 0; }

h1 {
  font-size: 3.8rem;
  line-height: 3.8rem; }

h2 {
  font-size: 2.8rem;
  line-height: 2.8rem; }

h3 {
  font-size: 50pt; }

h4 {
  font-size: 36pt; }

h5 {
  font-size: 25pt;
  margin-bottom: 25px; }

/* 
 * Media
 */
/* 
 * Buttons
 */
.button,
.wpforms-submit {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 25px;
  border: 2px solid transparent;
  border-radius: 6px;
  -webkit-appearance: none;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
  transition-property: background-color, border-color, color;
  transition-duration: 0.3s;
  outline: 0;
  text-align: center;
  min-width: auto;
  white-space: nowrap;
  text-decoration: none !important;
  line-height: initial;
  position: relative;
  background-color: #F3C27E;
  border-color: #F3C27E;
  color: #fff;
  transition: all 0.3s ease-in-out; }
  .button > span,
  .wpforms-submit > span {
    position: relative;
    display: block; }
    .button > span span,
    .wpforms-submit > span span {
      margin-left: 5px;
      vertical-align: middle;
      font-size: 12px; }
      .button > span span:before,
      .wpforms-submit > span span:before {
        color: #fff; }
  .button:hover, .button:focus,
  .wpforms-submit:hover,
  .wpforms-submit:focus {
    color: #fff;
    background-color: #BBC5B4;
    border-color: #BBC5B4; }
  .button:focus,
  .wpforms-submit:focus {
    background-color: #a1af97;
    border-color: #a1af97; }
  .button.ghost,
  .wpforms-submit.ghost {
    background: none;
    border: none; }

.button-outline {
  background-color: transparent;
  border-color: #F3C27E;
  color: #F3C27E; }
  .button-outline:hover, .button-outline:focus {
    background-color: #BBC5B4;
    border-color: #BBC5B4;
    color: #fff; }
  .button-outline:focus {
    background-color: shade(#BBC5B4, 10%);
    border-color: shade(#BBC5B4, 10%); }
  .button-outline.ghost {
    background: none;
    border: none; }

header, .hero {
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center; }
  header .wrapper, .hero .wrapper {
    position: relative;
    height: 100%; }
    header .wrapper .content, .hero .wrapper .content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      max-width: 800px; }
      header .wrapper .content h1, .hero .wrapper .content h1 {
        color: #fff;
        font-size: 3.8rem;
        margin: 0;
        padding: 0;
        line-height: 3.8rem;
        margin-bottom: 15px; }
      header .wrapper .content p, .hero .wrapper .content p {
        color: #fff; }

.socials {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 3; }
  .socials li {
    display: inline-block;
    margin-right: 20px; }
    .socials li a {
      transition: all 350ms ease; }
      .socials li a:hover {
        opacity: 0.7; }

.hero {
  height: 60vh;
  min-height: 550px; }

.block_one {
  height: 850px;
  position: relative; }
  .block_one .wrapper {
    position: relative;
    height: 100%; }
    .block_one .wrapper .content {
      position: absolute;
      max-width: 650px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2; }
      .block_one .wrapper .content h4 {
        font-family: "Merriweather", serif; }
      .block_one .wrapper .content p {
        display: block;
        margin: 20px 0; }
  .block_one img {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    height: 100%;
    width: auto; }
  @media (max-width: 767px) {
    .block_one {
      height: auto;
      padding: 300px 0; }
      .block_one img {
        width: 100%;
        height: auto; } }

.block_two .wrapper {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto; }
  .block_two .wrapper > * {
    flex: none;
    margin-right: calc(40px);
    margin-bottom: calc(40px);
    width: calc((100% / 1) - (40px * (1 - 1) / 1)); }
    @media (max-width: 575px) {
      .block_two .wrapper > *:nth-child(1n) {
        margin-right: 0; } }
    @media (min-width: 576px) and (max-width: 767px) {
      .block_two .wrapper > * {
        width: calc((100% / 1) - (40px * (1 - 1) / 1)); }
        .block_two .wrapper > *:nth-child(1n) {
          margin-right: 0; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .block_two .wrapper > * {
        width: calc((100% / 2) - (40px * (2 - 1) / 2)); }
        .block_two .wrapper > *:nth-child(2n) {
          margin-right: 0; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .block_two .wrapper > * {
        width: calc((100% / 2) - (40px * (2 - 1) / 2)); }
        .block_two .wrapper > *:nth-child(2n) {
          margin-right: 0; } }
    @media (min-width: 1280px) {
      .block_two .wrapper > * {
        width: calc((100% / 2) - (40px * (2 - 1) / 2)); }
        .block_two .wrapper > *:nth-child(2n) {
          margin-right: 0; } }
  @supports (display: grid) {
    .block_two .wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 40px; }
      @media (min-width: 576px) {
        .block_two .wrapper {
          grid-template-columns: repeat(1, 1fr); } }
      @media (min-width: 768px) {
        .block_two .wrapper {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 992px) {
        .block_two .wrapper {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 1280px) {
        .block_two .wrapper {
          grid-template-columns: repeat(2, 1fr); } }
      .block_two .wrapper > * {
        width: 100%;
        margin: 0; } }
  .block_two .wrapper .content {
    padding-top: 20px;
    color: #fff; }
    .block_two .wrapper .content h4 {
      color: #fff;
      margin-bottom: 20px;
      font-family: "Merriweather", serif; }

.normal-content .wrapper {
  max-width: 950px; }
  .normal-content .wrapper img {
    width: 100%;
    margin: 20px 0; }

.carousel {
  width: 100%;
  overflow: hidden;
  height: auto;
  position: relative; }
  .carousel .item {
    width: 100%;
    height: 100%;
    position: relative;
    height: 550px;
    background-size: cover;
    background-position: center; }
  .carousel .next-arrow {
    width: 50px;
    height: 50px;
    background-size: 100%;
    background-image: url("../images/right.svg");
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
    position: absolute; }
  .carousel .prev-arrow {
    width: 50px;
    height: 50px;
    top: 50%;
    position: absolute;
    z-index: 4;
    transform: translateY(-50%);
    left: 20px;
    background-size: 100%;
    background-image: url("../images/left.svg");
    background-position: center; }

/*
 * Helper classes
 */
.box-shadow {
  box-shadow: 0px 5px 8px 1px #d8d8d8; }

.text-shadow {
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); }

.centered {
  margin: 0 auto;
  text-align: center;
  display: block; }

.centered-vertical {
  display: flex !important;
  align-items: center;
  justify-content: center; }

.background-image {
  background-size: cover;
  background-position: center; }

.overlay {
  position: relative; }
  .overlay::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(48, 49, 51, 0.5);
    z-index: 1; }

.reset, nav ul, .socials {
  margin: 0;
  padding: 0;
  list-style: none; }

.embed {
  position: relative;
  overflow: hidden;
  padding-top: -56.25%; }
  .embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.section-title {
  position: relative;
  font-size: 4rem;
  text-align: center; }
  .section-title::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 100%;
    height: 6px;
    max-width: 120px;
    background: #F3C27E;
    transform: translateX(-50%); }
  @media (max-width: 767px) {
    .section-title {
      font-size: 2rem; } }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@keyframes cycle {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
