/* 
 * Layout base
 */

body {
  width: 100%;
  background: $white;
}
  
a {
  color: $primary-color;
  text-decoration: none;
} 

section {
  position: relative; 
  padding: 80px 0;
}

.wrapper {
  width: 100%;
  max-width: 1370px;
  margin: 0 auto;
  position: relative;
  display: block;
  padding: 0 20px;

  @include respond-below(xl) {
    padding: 0 40px;
    // max-width: 1260px;
  }
  @include respond-below(md) {
    padding: 0 20px;
  }
}
  