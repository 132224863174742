/* 
 * Headings
 */

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    line-height: 1.5;
    color: $grey-darkest;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    font-size: 3.8rem;
    line-height: 3.8rem;
  }
  
  h2 {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  
  h3 {
    font-size: 50pt;
  }
  
  h4 {
    font-size: 36pt;
  }

  h5 {
    font-size: 25pt;
    margin-bottom: 25px;
  }