/*
 * Helper classes
 */ 

// Simple box-shadow
.box-shadow {
    box-shadow: 0px 5px 8px 1px rgba($grey-lighter, 1);
}
  
// Simple text-shadow
.text-shadow {
    text-shadow: 0px 3px 6px rgba(0,0,0,0.3);
}
  
// Center text and elements horizontal
.centered {
    margin: 0 auto;
    text-align: center;
    display: block;
}
  
// Center text and elements vertically
.centered-vertical {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
  
// Background image
.background-image {
    background-size: cover;
    background-position: center;
}
  
// Overlay (zwart)
.overlay {
    position:relative;
    &::after {
        content: '';
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        height: 100%;
        width: 100%;
        background: rgba(#303133 ,0.5);  
        z-index: 1;
    }
}
  
// Reset lists
.reset {
    margin: 0;
    padding: 0;
    list-style: none;
}
  
// Embedded content
.embed {
    position: relative;
    overflow: hidden;
    padding-top: -56.25%;
  
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
}  

.section-title {
    position: relative;
    font-size: 4rem;
    text-align: center;

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -20px;
        width: 100%;
        height: 6px;
        max-width: 120px;
        background: $primary-color;
        transform: translateX(-50%);
    }

    @include respond-below(md){
        font-size: 2rem;
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}