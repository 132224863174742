.carousel {
   width: 100%;
   overflow: hidden;
   height:auto;
   position: relative;

   .item {
      width: 100%;
      height: 100%;
      position: relative;
      height: 550px;
      background-size: cover;
      background-position: center;
   }

   .next-arrow {
      width: 50px;
      height: 50px;
      background-size: 100%;
      background-image:url('../images/right.svg');
      background-position: center;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      cursor: pointer;
      position: absolute;

   }

   .prev-arrow {
      width: 50px;
      height: 50px;
      top: 50%;
      position: absolute;
      z-index: 4;
      transform: translateY(-50%);
      left: 20px;
      background-size: 100%;
      background-image:url('../images/left.svg');
      background-position: center;
   }
}