.quote {
   padding: 80px 0;

   .wrapper {
      font-family: $font-family-special;
      font-size: 3.8rem;
      line-height: 4rem;
      text-align: center;
      max-width: 800px;
      font-style: italic;
   }
}

footer {
   background-color: $primary-color;
   padding: 40px 0;
   color: $white;
   font-size: 1rem;

   .wrapper {
      @include grid(1,1,2,3,3,20px);
      
      .branding {
         img {
            width: 120px;
            margin-bottom: 20px;
         }
      }

      .times, .address {
         p {
            font-size: 1rem;
         }

         .socials {
            position: relative !important;
            margin-left: 20px;
            margin-top: 35px !important;
         }
      }
   }
}