/* 
 * Buttons
 */

.button, 
.wpforms-submit  {
    @include button;

    &.ghost {
        background: none; 
        border: none;
    }
}

.button-outline{
    @include button-outline;

    &.ghost {
        background: none;
        border: none;
    }
}