.address-nav {
   position: absolute;
   z-index: 10;
   width: 100%;
   top: 0;
   left: 0;
   right: 0;
   border-bottom: 1px solid $white;
   height: 50px;
   overflow: hidden;

   .get_address {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: $white;
      font-size: 1rem;
      right: 0;
      padding: 20px 45px;
      box-sizing: border-box;
      border-left: 1px solid $white;
   }
}

nav {
   position: fixed;
   top: 0;
   left: 0;
   top: 0;
   height: 80px;
   width: 100%;
   z-index: 99;
   padding: 0 20px;
   top: 50px;
   transition: all 450ms ease;

   &.scrolled {
      background: $secondary-color;
      height: 60px;
      top: 0;
      box-shadow: 0px 10px 10px rgba($black, 0.1);

      img {
         width: 60px;
         margin-top: 10px;
      }

      ul {
         top: -5px !important; 

         li {
            &.active {
               a:not(.button) {
                  color: $grey-darker !important;
               }
            }
         }
      }
   }

   img {
      width: 140px;
      margin-top: 20px;
      transition: all 350ms ease;

      @include respond-below(md){
         width: 80px;
      }
   }

   .hamburger {
      display: none;
      width: 30px;
      height: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      cursor: pointer;
      z-index: 100;

      span {
         display: block;
         background: $white;
         border-radius: 35px;
         height: 2px;
         width: 100%;
         position: absolute;
         transition: all 450ms ease;

         &:first-child {
            top: 0;
         }

         &:nth-child(2){
            top: 50%; 
            transform: translateY(-50%);
         }
         
         &:last-child {
            top: 100%;
         }
      }

      @include respond-below(md){
         display: block;
      }

      &.active {
         span {
            background: $secondary-color;

            &:first-child {
               top: 50%; 
               transform: translateY(-50%);
               transform: rotate(-45deg);
            }
   
            &:nth-child(2){
               top: 50%; 
               transform: translateY(-50%);
               width: 0px;
               opacity: 0;
            }
            
            &:last-child {
               top: 50%; 
               transform: translateY(-50%);
               transform: rotate(45deg);
            }
         }
      }
   }

   ul {
      @extend .reset;
      position: absolute;
      right: 20px;
      top: 0;
      transition: all 450ms ease;
      z-index: 3;

      li {
         list-style: none;
         display: inline-block;

         a {
            padding: 20px 35px; 
            display: block;
            color: $white;
            font-size: 1rem;

            &:hover {
               color: $primary-color;
            }
         }

         &.active {
            a {
               color: $primary-color;
            }  
         }
      }

      @include respond-below(md){
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         height: 100vh;
         width: 100%;
         background: rgba($white, 0.95);
         text-align: center;
         transform: translateY(-100%);
         opacity: 0;
         visibility: hidden;

         li {
            display: block;

            a {
               color: $black;
            }

            &.active {
               a {
                  color: $primary-color;
               }
            }
         }
      }
   }

   &.open {
      top: 0;

      ul {
         padding-top: 20%; 
         opacity: 1;
         visibility: visible;
         transform: translateY(0%);
      }
   }
}