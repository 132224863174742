/* 
 * Mixins
 */

// Text overflow
@mixin text-overflow($font-size, $line-height, $lines-to-show) {
    display: block;
    display: -webkit-box;
    height: calc(#{$font-size}*#{$line-height}*#{$lines-to-show});
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

// Ratio
@mixin ratio($x,$y, $pseudo: false) {
  $padding: unquote( ( $y / $x ) * 100 + '%' );
  @if $pseudo {
      &:before {
          @include pseudo($pos: relative);
          width: 100%;
          padding-top: $padding;
      }
  } @else {
      padding-top: $padding;
  }
}

// Hardware acceleration - for animation
@mixin hardware($backface: true, $perspective: 1000) {
  @if $backface {
      backface-visibility: hidden;
  }
  perspective: $perspective;
}