.block_one {
   height: 850px;
   position: relative;

   .wrapper {
      position: relative;
      height: 100%;

      .content {
         position: absolute;
         max-width: 650px;
         top: 50%;
         transform: translateY(-50%);
         z-index: 2;

         h4 {
            font-family: $font-family-special;
            //color: darken($secondary-color, 30%);
         }

         p {
            display: block;
            margin: 20px 0;
         }
      }
   }

   img {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      height: 100%;
      width: auto;
   }

   @include respond-below(md){
      height: auto;
      padding: 300px 0;

      img {
         width: 100%;
         height: auto;
      }
   }
}

.block_two {
   .wrapper {
      @include grid(1,1,2,2,2,40px); 

      .content {
         padding-top: 20px;
         color: $white;

         h4 {
            color: $white;
            margin-bottom: 20px;
            font-family: $font-family-special;
         }
      }
   }
}

.normal-content {
   .wrapper {
      max-width: 950px;

      img {
         width: 100%;
         margin: 20px 0; 
      }
   }
}